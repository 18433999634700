.customInputContainer {
  display: flex;
  padding: 10px;
  flex-grow: 1;
}

.customInput {
  height: 50px;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 4px;
  box-shadow: none;
  padding: 10px;
}
