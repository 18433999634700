.favorites-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.favorites-page th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.favorite-delete {
  background-color: #ff4747;
}

.favorite-delete-btns {
  display: flex;
  padding: 12px 20px;
  gap: 20px;

  * {
    width: 100%;
  }
}

.favorite-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4px 0;

  * {
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 0%;
  }

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.favorites-search-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
}

.favorites-company-select {
  width: 120px;
}
